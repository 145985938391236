// extracted by mini-css-extract-plugin
export var Basic = "Post-module--Basic--28KRd";
export var basicContent = "Post-module--basicContent--1NWyz";
export var contentContainer = "Post-module--contentContainer--ELBhC";
export var htmlContent = "Post-module--htmlContent--30GTp";
export var textInlineWithImage = "Post-module--textInlineWithImage--3-jE_";
export var title = "Post-module--title--3JQES";
export var underLine = "Post-module--underLine--F7uti";
export var imageContainer = "Post-module--imageContainer--2H7jr";
export var image = "Post-module--image--2Ojut";
export var date = "Post-module--date--2voUI";
export var day = "Post-module--day--2YopO";
export var month = "Post-module--month--38UPf";