import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import ShareButtons from "../../components/ShareButtons"
import Content, { HTMLContent } from "../../components/Content";

import * as styles from "./Post.module.scss"
import bgImage from "../../../static/assets/hero-banner.png"

const HeroBannerData = {
  sellPage: {
    title: "Avveckla aktiebolag - Sälj bolaget till oss för snabbavveckling!",
    buttonText: "LÄS MER",
    link: "/avveckla-aktiebolag",
  },
  buyPage: {
    title: "Lagerbolag – Det enkla sättet att starta aktiebolag!",
    buttonText: "LÄS MER",
    link: "/kopa-lagerbolag",
  },
}

const ContentBottomData = {
  sellPage: {
    title: "Avveckla Ditt Aktiebolag idag!",
    buttonText: "BESTÄLL OFFERT",
    link: `${process.env.GATSBY_HOST_URL}/avveckla-aktiebolag/pris`,
    text:
      "Vi har ett marknadsledande erbjudande på snabbavveckling av aktiebolag. Vi återkommer med en kostnadsfri offert inom 2 timmar!",
  },
  buyPage: {
    title: "Köp ditt lagerbolag av oss idag!",
    buttonText: "Beställ Nu",
    link: `${process.env.GATSBY_HOST_URL}/kopa-lagerbolag/pris`,
    text:
      "Vi har ett marknadsledande erbjudande på våra lagerbolag. Vi kan ha ditt nya bolag klart inom 2 timmar!",
  },
}

export const BlogPostTemplate = ({
  title,
  featuredImage,
  imageSrc,
  slug,
  html,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;
  
  return (
    <div className={styles.Basic}>
      <div className={styles.basicContent}>
  
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.underLine} />
  
        {featuredImage && (
          <div className={styles.imageContainer}>
            {typeof featuredImage === 'string'
              ? (
                // This fallback is for NetlifyCMS preview:
                <img src={featuredImage} alt={title} className={styles.image} />
              ) : (
                <GatsbyImage
                  image={
                    featuredImage.childImageSharp.gatsbyImageData
                  }
                  alt={title}
                  className={styles.image}
                />
              )
              
            }
          </div>
        )}
  
        <div className={styles.contentContainer}>
  
          <ShareButtons
            title={title}
            url={String(typeof window !== "undefined" && window.location)}
            featuredImage={imageSrc}
          />
  
          <div className={styles.htmlContent}>
            {slug === "/kopa-lagerbolag/hur-gar-det-till-att-kopa-lagerbolag" ||
            slug === "/avveckla-aktiebolag/snabbavveckling-av-aktiebolag" ? (
              <PostContent className={styles.textInlineWithImage} content={html} />
            ) : (
              <PostContent content={html} />
            )}
          </div>
        
        </div>
        
      </div>
    </div>
  )
};

const Post = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  const imageSrc = frontmatter.featuredImage
    ? getSrc(frontmatter.featuredImage)
    : ""

  return (
    <Layout
      className="page"
      sidebar
      pageType={frontmatter.pageType}
      showBottomContent
      showTopContent
      heroData={HeroBannerData}
      bgImage={bgImage}
      contentBottomData={ContentBottomData}
    >
      <SEO
        title={frontmatter.metaTitle ? frontmatter.metaTitle : frontmatter.title}
        description={
          frontmatter.metaDescription ? frontmatter.metaDescription : excerpt
        }
        image={imageSrc}
        article={true}
      />
      
      <BlogPostTemplate
        title={frontmatter.title}
        featuredImage={frontmatter.featuredImage}
        imageSrc={imageSrc}
        slug={frontmatter?.slug}
        html={html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query PostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 148)
      frontmatter {
        date(formatString: "D MMM")
        slug
        title
        pageType
        metaTitle
        metaDescription
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
